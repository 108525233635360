body {
  margin: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-root, .MuiInputBase-root, .MuiButtonBase-root {
  font-family: 'Helvitica' !important
}

.MuiTypography-root {
  color: white;
  border-color: white;
}

.MuiFormLabel-root, .MuiOutlinedInput-notchedOutline, .MuiInputBase-input{
  color: white !important;
  border-color: white !important;
}

.Menu-modal {
  width: 50%;
  height: 50%;
  color: white; 
  background-color: black; 
  position: absolute !important;
  top: 25% !important;
  left: 25% !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 30px rgba(90, 90, 90, 0.8) inset;
}